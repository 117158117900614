import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import darkRitualAbi from '../../contract/contract-dark-ritual.abi.json';
import coinAbi from '../../contract/contract-coin.abi.json';
import { KaikasService } from './kaikas.service';
import { environment } from '../../../environments/environment';
import { take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CatService } from './cat.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResponseSnackbarComponent } from 'src/app/shared/components/response-snackbar/response-snackbar.component';



//return(price, isEnabled, canDoDarkRitual(cat), isInQueue(cat), amountDark, amountWhite);
export interface DarkRitualStatus {
  catId: number;
  price: number;
  isEnabled: boolean,
  canDoDarkRitual: boolean;
  isInQueue: boolean;
  amountDark: number;
  amountWhite: number;
  noOfCoupons: number;
}


@Injectable({
  providedIn: 'root'
})
export class DarkRitualService {

  constructor(
      private window: Window,
      private kaikasService: KaikasService,
      private httpClient: HttpClient,
      private catService: CatService,
      private snackBar: MatSnackBar,
  ) { }

  private statusSubject:Map<number, ReplaySubject<DarkRitualStatus>> = new Map<number, ReplaySubject<DarkRitualStatus>>();

  public status(catId: number) {
    if (this.statusSubject.get(catId) == undefined)
    {
      this.statusSubject.set(catId, new ReplaySubject(1));
    }
    return this.statusSubject.get(catId)?.asObservable();
  }

  public refreshStatus(catId: number) {
    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      let caver = this.kaikasService.getCaver();
      let contract =  new caver.klay.Contract(darkRitualAbi, environment.contractDarkRitual, {gasPrice: '55500000000000' });

      let method = contract.methods.status( catId);
      method.estimateGas({from: account.address, nonce: null}).then((estimatedGas:any)=>{
         method.call({from: account.address, gas: estimatedGas}).then((data:any)=>{
          //return(price, isEnabled, canDoDarkRitual(cat), isInQueue(cat), amountDark, amountWhite, coupons);
          let status:DarkRitualStatus = {
            catId: catId,
            price: this.kaikasService.fromWei(data["0"]),
            isEnabled: data["1"],
            canDoDarkRitual: data["2"],
            isInQueue: data["3"],
            amountDark: data["4"],
            amountWhite: data["5"],
            noOfCoupons: data["6"]
          }
          if (this.statusSubject.get(catId) == undefined)
          {
            this.statusSubject.set(catId, new ReplaySubject(1));
          }
          this.statusSubject.get(catId)?.next(status);
         });
      });
    });
  }

  public makeAllowance(): Observable<void> {
    let caver = this.kaikasService.getCaver();
    let ret:Subject<void> = new Subject<void>();
    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      let value = this.kaikasService.toWei(500000000);
      let contract =  new caver.klay.Contract(coinAbi, environment.contractCoin, { gasPrice: '25000000000' });

      let method = contract.methods.approve(environment.contractDarkRitual, value);
      method.estimateGas({from: account.address, nonce: null}).then((estimatedGas:any)=>{
        return method.send({from: account.address, gas: estimatedGas});
      }).then(()=>{
        ret.next();
      });
    });
    return ret.asObservable();
  }

  public hasAllowanceToDoRitual():Observable<boolean> {
    let caver = this.kaikasService.getCaver();

    let ret:Subject<boolean> = new Subject<boolean>();
    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      if (account.isConnected == true) {
        let value = this.kaikasService.toWei(100000000);
        let contract =  new caver.klay.Contract(coinAbi, environment.contractCoin, { gasPrice: '25000000000' });
        contract.call("allowance", account.address, environment.contractDarkRitual).then((allowance:any)=>{
          ret.next(Number(allowance) >= Number(value));
        });
      } else {
        ret.next(false);
      }
    });
    return ret.asObservable();
  }

  makeRitualCoupon(catId: any) {
    let caver = this.kaikasService.getCaver();

    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      let contract =  new caver.klay.Contract(darkRitualAbi, environment.contractDarkRitual, { gasPrice: '25000000000' });

      let method = contract.methods.makeRitualCoupon(catId);
      method.estimateGas({from: account.address, nonce: null}).then((estimatedGas:any)=>{
        return method.send({from: account.address, gas: estimatedGas});
      }).then(()=>{
        this.snackBar.openFromComponent(ResponseSnackbarComponent, {
          horizontalPosition: "center",
          verticalPosition: "bottom",
          duration: 6000,
          data: "REFRESHING... PLEASE WAIT"
        });
        this.refreshStatus(catId);
        this.refreshServer(catId);
      });
    });
  }

  public makeRitual(catId: number, price:number=0) {
    let caver = this.kaikasService.getCaver();

    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      let contract =  new caver.klay.Contract(darkRitualAbi, environment.contractDarkRitual, { gasPrice: '25000000000' });

      let method = contract.methods.makeRitual(catId);
      method.estimateGas({from: account.address, nonce: null}).then((estimatedGas:any)=>{
        return method.send({from: account.address, gas: estimatedGas});
      }).then(()=>{
        this.snackBar.openFromComponent(ResponseSnackbarComponent, {
          horizontalPosition: "center",
          verticalPosition: "bottom",
          duration: 6000,
          data: "REFRESHING... PLEASE WAIT"
        });
        this.refreshStatus(catId);
        this.refreshServer(catId);
      });
    });
  }

  public makeRitualKlay(catId: number, price:number=0) {
    let caver = this.kaikasService.getCaver();

    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      let contract =  new caver.klay.Contract(darkRitualAbi, environment.contractDarkRitual, { gasPrice: '25000000000' });

      let method = contract.methods.makeRitualKlay(catId);
      let value = this.kaikasService.toWei(price);
      method.estimateGas({from: account.address, value: value, nonce: null}).then((estimatedGas:any)=>{
        return method.send({from: account.address, value: value, gas: estimatedGas});
      }).then(()=>{
        this.snackBar.openFromComponent(ResponseSnackbarComponent, {
          horizontalPosition: "center",
          verticalPosition: "bottom",
          duration: 6000,
          data: "REFRESHING... PLEASE WAIT"
        });
        this.refreshStatus(catId);
        this.refreshServer(catId);
      });
    });
  }


  refreshServer(catId: number) {
    this.refreshNest().toPromise().then((data)=>{
      this.refreshType(catId).toPromise().then((data)=> {
        this.catService.refreshHeroCatObservable(catId);
      });
    });
  }

  refreshNest():Observable<string> {
    return this.httpClient.get( environment.tokenUrl + "/api/dark-ritual", {responseType: 'text'});
  }


  refreshType(catId: number):Observable<string> {
    return this.httpClient.get( environment.tokenUrl + "/api/refresh-type?cat=" + catId, {responseType: 'text'});
  }

}
