<div fxLayout="column">
  <h1 mat-dialog-title fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex fxLayoutAlign="center" class="modal-title">Cat Town Tube</div>
  </h1>
  <p>An andvance teleportation system that allows butlers to transfer cats from one account to the other. Nick name "Tube" is from the acient times when Cats used underground railway.</p>
  <div mat-dialog-content class="modal-text" style="margin-bottom: 2rem; text-align: center;">
    <p>Please specify destination address:</p>
    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Address</mat-label>
      <input type="text" matInput [formControl]="addressFormControl" placeholder="0x...">
      <mat-error *ngIf="addressFormControl.hasError('required')">
        Address is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="addressFormControl.hasError('invalidAddress')">
        Address is <strong>invalid</strong>
      </mat-error>
    </mat-form-field>
    <br>
    <p textColor="warn" *ngIf="userDeniedTx">You have denied the transaction.</p>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()" class="modal-button">Not right now</button>
    <button *ngIf="(showAllowanceButton)" mat-raised-button color="primary" class="modal-button" (click)="approve()">Approve</button>
    <button [disabled]="showAllowanceButton ? 'disabled': null" mat-raised-button color="primary" class="modal-button" (click)="transfer()">Transfer</button>
  </div>
</div>
