import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import nftAbi from '../../contract/contract-nft.abi.json';
import { KaikasService } from '../../core/services/kaikas.service';
import { MintDialogComponent } from '../../shared/components/mint-dialog/mint-dialog.component';

declare var Caver: any;


@Component({
  selector: 'app-mint',
  templateUrl: './mint.component.html',
  styleUrls: ['./mint.component.scss']
})
export class MintComponent implements OnInit {
  contract: any;

  constructor(
    private window: Window,
    private kaikasService: KaikasService,
    public dialog: MatDialog
    ) {

  }
  public kaikas = null;
  public isConnected: boolean = false;
  public userAccount: string = "";
  public userBalance: number = 0;
  public contractAddress: string = environment.contractNft;
  public mintPrice: number = -1;
  public currentSupply: number = 0;
  public maxSupply: number = 0;
  public userMaxNfts:number = 0;
  public userLoggedIn:boolean = false;

  public textFC:FormControl = new FormControl('');

  caver:any;

  public async trySilentLogin() {
    let kaikas = null;
    if (typeof (this.window as any).klaytn !== 'undefined') {
      // Kaikas user detected. You can now use the provider.
      kaikas = (this.window as any).klaytn;
    } else {
      return;
    }
    if (kaikas.networkVersion != "8217") {
      return;
    }
    try {
      const accounts = await kaikas.enable()
    } catch (error) {
      return;
    }
    this.refresh();
  }

  public async refresh() {
    let kaikas = null;
    if (typeof (this.window as any).klaytn !== 'undefined') {
      // Kaikas user detected. You can now use the provider.
      kaikas = (this.window as any).klaytn;
    } else {
      this.openDialog("Minting requires Kaikas. Please install Kaikas.");
      return;
    }
    if (kaikas.networkVersion != environment.chanId) {
      this.openDialog(environment.invalidNetworkMessage);
      return;
    }
    try {
      const accounts = await kaikas.enable()
    } catch (error) {
      this.openDialog("Rejected? Well, good luck and come back again.");
      return;
    }

    this.userAccount = await kaikas.selectedAddress;


    this.caver = new Caver(kaikas);
    (this.window as any).caver = this.caver;
    const balance = await this.caver.klay.getBalance(this.userAccount);
    this.userBalance = Caver.utils.fromWei(balance);

    (this.window as any).contractAbi = nftAbi;
    (this.window as any).contractAddress = this.contractAddress;

    this.contract =  new this.caver.klay.Contract(nftAbi, this.contractAddress, { gasPrice: '25000000000' });
    (this.window as any).contract = this.contract;

    const mintPriceWei = await this.contract.call("mintPrice");
    this.mintPrice = Caver.utils.fromWei(mintPriceWei);
    this.maxSupply = await this.contract.call("maxSupply");
    this.currentSupply = await this.contract.call("nextMintIndex") -1;
    this.userMaxNfts = Math.floor(this.userBalance/this.mintPrice);
    this.userLoggedIn = true;
  }


  async buyNft(howMuch: number): Promise<void> {
    if (!this.userLoggedIn) {
      this.openDialog("Login with kaikas first.");
      return;
    }
    let kaikas = (this.window as any).klaytn;
    this.caver = new Caver(kaikas);
    let value = Caver.utils.toWei((this.mintPrice * howMuch) + "");
    (this.window as any).value = value;
    let method = this.contract.methods.mintPublic(this.userAccount, howMuch);
    (this.window as any).method = method;
    let estimatedGas = await method.estimateGas({from: this.userAccount, value: value, nonce: null});
    await method.send({from: this.userAccount, value: value, gas: estimatedGas});
    this.refresh();
  }

  ngOnInit(): void {
    //this.trySilentLogin();
  }

  openDialog(dialogText: string): void {
    const dialogRef = this.dialog.open(MintDialogComponent, {
      width: '450px',
      data: dialogText,
      autoFocus: false
    });
  }


}
