<div fxLayout="column" style="height: 100%;">

  <div class="p1-container" fxLayout="row" fxLayout.xs="column" fxLayoutGap="61px" fxLayoutAlign="center center">
    <img class="p1-img" src="./assets/friendly_cat.png" alt="friendly-cat" fxShow.gt-xs fxHide.xs>
    <div class="p1-content" fxLayout="column" fxLayoutAlign.xs="center center">
      <h1 class="p1-title">CAT TOWN PUNKS</h1>
      <img class="p1-img" src="./assets/friendly_cat.png" alt="friendly-cat" fxShow.xs fxHide.gt-xs>
      <p>There are new punks in the town. There are 20,000 unique generated NFTs.
        And they are ready to take over the world.</p>
      <a fxLayoutAlign="center center" mat-raised-button color="primary" href="https://opensea.io/collection/cat-town-punks" target="_blank" class="btn-primary p1-btn">BUY NOW</a>
    </div>
  </div>
  <!-- <app-market-countdown
    [title]="mintPriceAuction.title"
    [countdownDate]="mintPriceAuction.countdown">
    {{mintPriceAuction.text}} <a class="m-auction" routerLink="/mint">Hurry up!</a>
  </app-market-countdown> -->

  <!-- <div *ngIf="kaikas.loginInfo.isConnected">
    <app-market-countdown
      [title]="catCoinPresale.title"
      [secondsTillCountdown]="catCoinSecondsLeft">
      {{catCoinPresale.text}} <a class="m-auction" routerLink="/cat-coin">here!</a>
    </app-market-countdown>
  </div> -->
  <!-- <div *ngIf="!kaikas.loginInfo.isConnected" fxLayoutAlign="center center">
    <p style="margin-top: 10rem;">Login in to Kaikas Vallet to see CatCoin Presale Info</p>
  </div> -->
  <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 6rem;">
    <h2>Check out CatTownPunks on Youtube!</h2>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/Ff_7JVRtn1Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <br>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/iSxLAYLkeeY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>

  <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 6rem;">
    <h2>Where can you get CatCoin?</h2>
    <p>Swap on <a href="https://klayswap.com/exchange/swap?input=0xeddbbc44fe7cedaf0a2a7b40971a23dae82c1c8c&output=0x0000000000000000000000000000000000000000" target="_blank">KlaySwap.com</a></p>
    <p>Swap on <a href="https://klaytn.exchange.definix.com/#/swap?inputCurrency=0xeddbbc44fe7cedaf0a2a7b40971a23dae82c1c8c&outputCurrency=KLAY" target="_blank" style="text-decoration: underline;">DEFINIX</a></p>
    <p>Stats on <a href="https://dexata.kr/?tokenA=0xeddbbc44fe7cedaf0a2a7b40971a23dae82c1c8c&tokenB=0x0000000000000000000000000000000000000000" target="_blank">dexata.kr</a></p>
    <p>CatCoin contract: <span style="text-decoration: underline;" class="word-break">0xEddbbc44fe7CeDaf0a2A7b40971A23dae82C1C8c</span> </p>
  </div>

  <app-height-spacer height="7rem"></app-height-spacer>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center">
    <div fxLayout="row" fxLayoutGap="2rem" fxLayoutAlign="center center">
      <div fxLayout="column" fxLayoutAlign.xs="center center">
        <h2>HOT CAT News</h2>
        <img fxShow.xs fxHide.gt-xs class="p3-img2" src="./assets/your_story.png" alt="friendly-cat">
        <p class="p3-text">There is a new event going on.</p>
        <a routerLink="hot-cat" mat-raised-button color="primary" class="btn-primary p2-btn" fxLayoutAlign="center center" style="margin-top: 0.5rem;">More info</a>
      </div>
    </div>
    <img fxShow.gt-xs fxHide.xs class="p3-img" src="./assets/your_story.png" alt="friendly-cat">
  </div>
  <app-height-spacer height="1rem"></app-height-spacer>

  <app-cat-story [catStory]="catStories[2]" [even]="false" [last]="false" class="i-story"></app-cat-story>
  <app-cat-story [catStory]="catStories[3]" [even]="true" [last]="false"></app-cat-story>

  <app-height-spacer height="15rem"></app-height-spacer>


  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center">
    <div fxLayout="row" fxLayoutGap="2rem" fxLayoutAlign="center center">
      <div fxLayout="column" fxLayoutAlign.xs="center center">
        <h2>Do you want your cat to appear on this site?</h2>
        <img fxShow.xs fxHide.gt-xs class="p3-img2" src="./assets/your_story.png" alt="friendly-cat">
        <p class="p3-text">No problem, it won't cost you a dime. Well actualy it will cost you some
          creativity. You need to write a short story about your cat and send it to our address. And your cat will join the hall of fame.</p>
        <a href="mailto:info@cattownpunks.com?subject=Story About My Cat" mat-raised-button color="primary" class="btn-primary p2-btn" fxLayoutAlign="center center" style="margin-top: 0.5rem;">Write Now</a>
      </div>
    </div>
    <img fxShow.gt-xs fxHide.xs class="p3-img" src="./assets/your_story.png" alt="friendly-cat">
  </div>
  <app-height-spacer height="15rem"></app-height-spacer>
  <mat-divider style="margin: 0 70px;"></mat-divider>
  <app-height-spacer height="7rem"></app-height-spacer>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" >
    <h2 class="p4-title">CAT STATISTICS</h2>
    <div fxLayout="row" fxLayoutGap="40px">
      <div fxLayout="column">
        <h3 class="p4-sub-title">NO.</h3>
        <p class="p4-number">11,319</p>
        <p class="p4-number">6,580</p>
        <p class="p4-number">1,750</p>
        <p class="p4-number">328</p>
        <p class="p4-number">23</p>
      </div>
      <div fxLayout="column">
        <h3 class="p4-sub-title">RARITY</h3>
        <p class="p4-text">COMMON CATS</p>
        <p class="p4-text">UNCOMMON CATS</p>
        <p class="p4-text">RARE CATS</p>
        <p class="p4-text">EPIC CATS</p>
        <p class="p4-text">LEGENDARY CATS</p>
      </div>
    </div>
  </div>
  <app-height-spacer height="8rem"></app-height-spacer>
  <mat-divider style="margin: 0 70px;"></mat-divider>
  <app-height-spacer height="19rem"></app-height-spacer>


  <div class="p5-card" fxLayout="column">
    <h1 class="p5-title">LEGENDARY CLUB</h1>
    <h2 class="p5-subtitle">The owner of legendary will get access to Legendary club</h2>
    <h4 class="p5-benefits">Benefits:</h4>
    <p class="p5-text">- Exclusive Access To Our Next Projects</p>
    <p class="p5-text">- Invite To Pre-Presale Of Our Next Projects</p>
  </div>

  <app-height-spacer height="160px"></app-height-spacer>
  <mat-divider style="margin: 0 70px;"></mat-divider>
  <app-height-spacer height="150px"></app-height-spacer>

  <div fxLayout="row" fxLayoutAlign="center">
    <div class="p6-card" fxLayout="row" fxLayout.xs="column" fxLayoutGap="5rem" fxLayoutAlign="center center">
      <img class="p6-img" src="./assets/how_to_get.png" alt="how_to_get_cat">
      <div class="p6-content" fxLayout="column">
        <h2 class="p6-title">How to get a Cat Town Punk?</h2>
        <p class="p6-text">Buy it on <a class="p6-link" href="https://opensea.io/collection/cat-town-punks" target="_blank">opensea.io</a> - a good way to cherry pick your favorite cat</p>
      </div>
    </div>
  </div>
  <app-height-spacer height="14rem"></app-height-spacer>


</div>
