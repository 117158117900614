import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, take } from 'rxjs/operators';
import { IKaikasTransaction, KaikasService } from 'src/app/core/services/kaikas.service';
import { ResponseSnackbarComponent } from 'src/app/shared/components/response-snackbar/response-snackbar.component';

@Component({
  selector: 'app-modal-collect-all-cat-coins',
  template: `
    <div fxLayout="column">
      <h1 mat-dialog-title fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex fxLayoutAlign="center" class="modal-title">{{title}}</div>
        <a mat-raised-button class="twitter-button" fxLayout="row" target="_blank" href="{{randowTweetHref}}">
          <mat-icon svgIcon="twitter-blue" class="modal-icon"></mat-icon>
          <span style="margin-left: 10px;">Tweet</span>
        </a>
      </h1>
      <div mat-dialog-content class="modal-text" style="margin-bottom: 2rem; text-align: center;">
        <p>Your cats have mined <span textColor="accent" style="font-weight: bold;">{{data.amount | number: '0.1-6' }}</span> CatCoins.</p>
        <p>Would like to collect them all right now?</p>
        <br>
        <p textColor="warn" *ngIf="userDeniedTx">You have denied the transaction.</p>
      </div>
      <div *ngIf="!isLoading" mat-dialog-actions align="end">
        <button mat-button (click)="onNoClick()" class="modal-button">Not right now</button>
        <button mat-raised-button color="primary" class="modal-button" (click)="collectCatCoins()">{{(data.type=="top50")?"Collect":"Collect all"}}</button>
      </div>

      <div *ngIf="isLoading" mat-dialog-actions align="end">
        <p>Please confirm on Kaikas Vallet</p>
      </div>
    </div>
  `,
  styles: [`
    .twitter-button {
      background-color: #1D9BF0;
      color:white;
      border-radius: 20px;
      font-family: Poppins;
      font-size: 16px;
      letter-spacing: 0.5px;
    }
    `]
})
export class ModalCollectAllCatCoinsComponent implements OnInit {

  public isLoading: boolean = false;
  public userDeniedTx: boolean = false;
  public title: string = "Congratulations";
  public titleIcon: string = "thumb_up_off_alt";
  public randowTweetHref: string = "";

  constructor(
    private _snackBar: MatSnackBar,
    private kaikas: KaikasService,
    public dialogRef: MatDialogRef<ModalCollectAllCatCoinsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  collectCatCoins() {
    this.isLoading = true;
    this.userDeniedTx = false;
    if (this.data.type == "top50") {
      this.kaikas.collectSelected(this.data.catIds).pipe(take(1)).subscribe(
        (data: IKaikasTransaction) => {
          if(data.transactionSuccessful) {
            this.userConfirmedTransaction()
            this.kaikas.fetchItems();
          } else {
            this.userDeninedTransaction()
          }
        }
      );
    } else {
      this.kaikas.collectAll().pipe(take(1)).subscribe(
        (data: IKaikasTransaction) => {
          if(data.transactionSuccessful) {
            this.userConfirmedTransaction()
          } else {
            this.userDeninedTransaction()
          }
        }
      );
    }

  }

  userDeninedTransaction() {
    this.isLoading = false;
    this.userDeniedTx = true;
  }

  userConfirmedTransaction() {
    this.isLoading = false;
    this.dialogRef.close();
    this._snackBar.openFromComponent(ResponseSnackbarComponent, {
      horizontalPosition: "center",
      verticalPosition: "bottom",
      duration: 2000,
      data: `CATCOINS SUCCESSFULLY COLLECTED`
    });
  }

  initRandomTweet() {
    let catCoinFormatted = Number(this.data).toFixed(3);

    let tweetArray = [
      `http://twitter.com/share?text=Just got ${catCoinFormatted} CatCoins from NFT mining💰.%0A&url=https://cattownpunks.com&hashtags=CatCoin,CTP,NFTs,Klaytn,CATC,CRYPTO`,
      `http://twitter.com/share?text=CATC to the moon 🚀🌕. Just collected ${catCoinFormatted} mining.%0A&url=https://cattownpunks.com&hashtags=CatCoin,CTP,NFTs,Klaytn,CATC,CRYPTO`,
      `http://twitter.com/share?text=Money doesn't grow on tree. You need cats for money to grow.😼💵%0A&url=https://cattownpunks.com&hashtags=CatCoin,CTP,NFTs,Klaytn,CATC,CRYPTO`,
      `http://twitter.com/share?text=Proud $CATC owner. 😼😎 ${catCoinFormatted} just collected.%0A&url=https://cattownpunks.com&hashtags=CatCoin,CTP,NFTs,Klaytn,CATC,CRYPTO`,
      `http://twitter.com/share?text=Passive income? 🐱➡️💰➡️😎%0A Just received ${catCoinFormatted} $CATC with one click.%0A&url=https://cattownpunks.com&hashtags=CatCoin,CTP,NFTs,Klaytn,CATC,CRYPTO`
    ];
    let randomTweet = tweetArray[Math.floor(Math.random()* tweetArray.length)];

    this.randowTweetHref = randomTweet;
  }

  ngOnInit() {
    if(this.data < 1) {
      this.title = "CatCoin Info"
      this.titleIcon = "info";
    }
    this.initRandomTweet();

  }

}
