import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { ACCENT_COLOR, FONT_COLOR, FONT_COLOR_DARK, KLAY_COLOR, PRIMARY_COLOR } from 'src/app/core/constants/color-constant';

enum Color {
  PRIMARY = 'primary',
  ACCENT = 'accent',
  WARN ='warn',
  FONT = 'font',
  FONT_DARK = 'font-dark',
  KLAY = 'klay'
}


@Directive({
  selector: '[textColor]'
})
export class TextColorDirective implements OnInit {
  @Input() textColor!: string;

  constructor(private el: ElementRef) {
  }
  ngOnInit(): void {
    switch (this.textColor) {
      case Color.PRIMARY:
        this.el.nativeElement.style.color = PRIMARY_COLOR;
        break;

      case Color.ACCENT:
        this.el.nativeElement.style.color = ACCENT_COLOR;
      break;

      case Color.WARN:
        this.el.nativeElement.style.color = 'red';
      break;

      case Color.FONT:
        this.el.nativeElement.style.color = FONT_COLOR;
      break;

      case Color.FONT_DARK:
        this.el.nativeElement.style.color = FONT_COLOR_DARK;
      break;

      case Color.KLAY:
        this.el.nativeElement.style.color = KLAY_COLOR;
      break;

      default:
        this.el.nativeElement.style.color = PRIMARY_COLOR;
        break;
    }
  }

}
