<div fxLayout="column" class="cats-height" fxLayoutAlign="center center">
  <h1>Voucher NFTs</h1>

  <div fxLayout.gt-xs="row wrap" fxLayout.xs="column" fxLayoutAlign="center center">
    <div *ngFor="let item of items">
      <app-other-item-presentation-card [item]="item"></app-other-item-presentation-card>
    </div>

  </div>
  <div *ngIf="(items==undefined || items.length == 0)">
    You don't have any coupons in your account. Follow us on <a href="https://twitter.com/CatTownPunks" target="_blank">twitter</a>.
    <app-height-spacer height="15rem"></app-height-spacer>
  </div>
