<div *ngIf="showCat && catItem as async" class="pp-card" fxLayout="column" [ngStyle]="{'border': (isChecked) ? 'solid 4px #B00' : 'none','opacity': (isDisabled)?'30%':'100%'}" style="position: relative; min-height: 470px">
  <mat-icon *ngIf="isChecked" style="color: #B00; position:absolute; top: 0px; right:0.5em; font-size:3em">check_circle</mat-icon>

    <div fxLayout="column" style="padding: 20px 20px 10px 20px">
      <p class="pp-title-o">MeYou</p>
      <p class="pp-title-o"> <span class="pp-number">#{{catItem.number}}</span></p>
    </div>
    <img [src]="catItem.image + '?size=small'" alt="cat-img" class="pp-img">
    <p class="pp-name">{{catItem.name}}</p>
    <p class="pp-rare">{{catItem.attributes[0].value}}</p>

  <p *ngIf="(showMenu==true)" class="pp-footer">
    <button *ngIf="(checkMode==false)" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions...">
      <mat-icon>more_horiz</mat-icon>
    </button>
  </p>
</div>
<mat-menu #menu="matMenu">
<button mat-menu-item  (click)="startTransfer()">
  <mat-icon>move_up</mat-icon>
  <span>Transfer Cat</span>
</button>
</mat-menu>
