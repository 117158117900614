<div fxLayout="column" fxLayoutAlign="center center" style="margin-bottom: 5rem;">
  <app-height-spacer height="2rem"></app-height-spacer>
    <form [formGroup]="whitelistForm" (ngSubmit)="whitelistForm.valid && checkAddress()"  >
      <mat-label fxLayoutAlign="center center"><p>Enter an address</p></mat-label>
      <input matInput [formControl]='userAddressControll()' class="p-new-name-input" maxlength="42"  minlength="42" >
      <div *ngIf="whitelistForm.invalid">
        <mat-error *ngIf="whitelistForm.invalid&&isSubmitted" style="text-align:center;">This Field is Required.</mat-error>
      </div>

      <div class="form-group text-center">
        <button mat-button class="profile" (click)="submitForm()" type="submit" style="margin-top: 2rem; margin-left: 2.5rem;">Check</button>
      </div>
    </form>

</div>
<ng-container *ngIf="roles">
  <div  class="row container" style="margin-bottom: 5rem;">
    <p>Address <b>{{address}}</b> has been whitelisted:</p>
    <ng-container *ngFor="let role of roles">
      <li>
        {{role.roleName}}
      </li>
    </ng-container>
  </div>
</ng-container>
