import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import hotCatAbi from '../../contract/contract-hot-cat-2.abi.json';
import coinAbi from '../../contract/contract-coin.abi.json';
import { KaikasService } from './kaikas.service';
import { environment } from '../../../environments/environment';
import { take } from 'rxjs/operators';

export interface HotCatPlayer {
  address: string;
  addressFormated: string;
  timeHolded: number;
  amountToReturn: string;
  winner1: boolean;
  winner2: boolean;
  winner3: boolean;
}

export interface HotCatStatus {
  currentPrice: number;
  currentPriceFormated: number,
  isAvailableToBid: boolean;
  waitTillNextBid: number;
  currentHolder: string;
  currentHolder2: string;
  currentHolderFormated: string;
  currentHolderFormated2: string;
  rewardIdLongest: number;
  rewardIdMaxPrice: number;
  rewardIdMaxPrice2: number;
  secTillEnd: number;
  players: HotCatPlayer[];
  //(currentPrice, isAvailableToBid, waitTillNextBid, currentHolder, rewardIdLongest, rewardIdMaxPrice, secTillEnd)
}


@Injectable({
  providedIn: 'root'
})
export class HotCatService2 {
  constructor(
      private window: Window,
      private kaikasService: KaikasService
  ) {
  }

  private statusSubject:Map<string, ReplaySubject<HotCatStatus>> = new Map<string, ReplaySubject<HotCatStatus>>();

  public status(contractAddress: string) {
    if (this.statusSubject.get(contractAddress) == undefined)
    {
      this.statusSubject.set(contractAddress, new ReplaySubject(1));
    }
    return this.statusSubject.get(contractAddress)?.asObservable();
  }

  public hasAllowanceToBid(contractAddress: String):Observable<boolean> {
    let caver = this.kaikasService.getCaver();

    let ret:Subject<boolean> = new Subject<boolean>();

    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      if (account.isConnected == true) {
        let value = this.kaikasService.toWei(100000000);
        let contract =  new caver.klay.Contract(coinAbi, environment.contractCoin, { gasPrice: '25000000000' });
        contract.call("allowance", account.address, contractAddress).then((allowance:any)=>{
          ret.next(Number(allowance) >= Number(value));
        });
      } else {
        ret.next(false);
      }
    });
    return ret.asObservable();
  }

  public bid(contractAddress: string) {
    let caver = this.kaikasService.getCaver();

    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      let contract =  new caver.klay.Contract(hotCatAbi, contractAddress, { gasPrice: '25000000000' });

      let method = contract.methods.doBid();
      method.estimateGas({from: account.address, nonce: null}).then((estimatedGas:any)=>{
        return method.send({from: account.address, gas: estimatedGas});
      }).then(()=>{
        this.refreshStatus(contractAddress);
      });
    });
  }

  public makeAllowance(contractAddress: string): Observable<void> {
    let caver = this.kaikasService.getCaver();
    let ret:Subject<void> = new Subject<void>();
    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      let value = this.kaikasService.toWei(500000000);
      let contract =  new caver.klay.Contract(coinAbi, environment.contractCoin, { gasPrice: '25000000000' });

      let method = contract.methods.approve(contractAddress, value);
      method.estimateGas({from: account.address, nonce: null}).then((estimatedGas:any)=>{
        return method.send({from: account.address, gas: estimatedGas});
      }).then(()=>{
        ret.next();
      });
    });
    return ret.asObservable();
  }

  public refreshStatus(contractAddress: string) {
    this.kaikasService.loginInfoObserver.pipe(take(1)).subscribe((account)=>{
      let caver = this.kaikasService.getCaver();
      let contract =  new caver.klay.Contract(hotCatAbi, contractAddress, { gasPrice: '55500000000000' });
      contract.call("getStatusAll").then((data:any)=>{
        //(currentPrice, isAvailableToBid, , , , , )
        let status:HotCatStatus = {
          currentPrice: data["0"],
          currentPriceFormated: this.kaikasService.fromWei(data["0"]),
          isAvailableToBid: data["1"],
          waitTillNextBid: data["2"],
          currentHolder: data["3"],
          currentHolder2: data["4"],
          currentHolderFormated: this.kaikasService.shortAddress(data["3"]),
          currentHolderFormated2: this.kaikasService.shortAddress(data["4"]),
          rewardIdLongest: data["5"],
          rewardIdMaxPrice: data["6"],
          rewardIdMaxPrice2: data["7"],
          secTillEnd: data["8"],
          players: []
        }


        let players:any[] = data["9"];
        let playerTimes:any[] = data["10"];
        let moneyToRetur:any[] = data["11"];
        for(let j=0; j<players.length; j++) {
          let player:HotCatPlayer = {
            address: players[j],
            addressFormated: this.kaikasService.shortAddress(players[j]),
            timeHolded: playerTimes[j],
            amountToReturn: this.kaikasService.fromWei(moneyToRetur[j]),
            winner1: (players[j].toUpperCase() == status.currentHolder.toUpperCase()),
            winner2: (players[j].toUpperCase() == status.currentHolder2.toUpperCase()),
            winner3: false
          };
          status.players.push(player);
        }
        status.players.sort((a,b) => b.timeHolded - a.timeHolded);
        if (status.players.length > 0)
          status.players[0].winner3 = true;

        if (this.statusSubject.get(contractAddress) == undefined)
        {
          this.statusSubject.set(contractAddress, new ReplaySubject(1));
        }
        this.statusSubject.get(contractAddress)?.next(status);
      });
    });
  }
}
