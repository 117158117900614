import { Injectable } from '@angular/core';
import { MatSpinner } from '@angular/material/progress-spinner';
import { HookParser, HookPosition, HookValue, HookComponentData, HookBindings, HookFinder } from 'ngx-dynamic-hooks';
import { BiddingGameComponent } from '../components/bidding-game/bidding-game.component';
import { OhterNftComponent } from '../components/ohter-nft/ohter-nft.component';
import { PagePresentationCardComponent } from '../components/page-presentation-card/page-presentation-card.component';
import { YoutubeVideoComponent } from '../components/youtube-video/youtube-video.component';

@Injectable({
  providedIn: 'root'
})
export class PageHookParser implements HookParser {

    constructor(private hookFinder: HookFinder) {


    }

    public findHooks(content: string, context: any): Array<HookPosition> {
      return this.hookFinder.findStandaloneHooks(content, /\[(.*?)\]/gm);
    }

    public loadComponent(hookId: number, hookValue: HookValue, context: any, childNodes: Array<Element>): HookComponentData {
      let inner = hookValue.openingTag.replace("[", "").replace("]", "");
      let all = inner.split(" ");
      if (all[0] == "cat")
        return {component: PagePresentationCardComponent};
      if (all[0] == "nft")
        return {component: OhterNftComponent};
      if (all[0] == "video")
        return {component: YoutubeVideoComponent};
      if (all[0] == "bidding_game")
        return {component: BiddingGameComponent};
      return {component: MatSpinner};
    }

    public getBindings(hookId: number, hookValue: HookValue, context: any): HookBindings {
        let inner = hookValue.openingTag.replace("[", "").replace("]", "").replace(/<\/span>/g, "").replace(/<span>/g, "");
        let all = inner.split(" ");
        if (all[0] == "cat") {
          return{
            inputs: {
                itemId: all[1]
            }
          }
        }
        if (all[0] == "bidding_game") {
          return{
            inputs: {
                contract: all[1]
            }
          }
        }
        if (all[0] == "video") {
          return{
            inputs: {
                videoId: all[1].replace("https://www.youtube.com/watch?v=", "")
            }
          }
        }
        if (all[0] == "nft") {
          return{
            inputs: {
                contract: all[1],
                token: all[2]
            }
          }
        }
        return {

        };
    }
}
