<div style="max-width: 1176px;">
  <div fxLayout="column" fxLayoutAlign="center center" style="margin-bottom: 5rem;">
    <h1 class="main-title">Collector Edition - dragon </h1>
    <h3 style="margin-bottom: 10px; margin-top: 10px;">First community driven bidding game</h3>
    <p>We proudly present bidding game sponsored by one of our Early adapters. Participate and try to win SheepFarm NFTs!</p>
    <a href="https://sheepfarm.io/" target="_blank" style="text-decoration: underline;"><img fxShow.gt-xs fxHide.xs class="p3-img" src="./assets/collector-dragon/sheepFarm-og.png" alt="friendly-sheep"><img></a>
    <p><a href="https://sheepfarm.io/" target="_blank" style="text-decoration: underline;">sheepfarm.io</a>
    </p>

    <ng-container *ngIf="status | async; else noGame; let st">

      <h2 style="margin-bottom: 10px; margin-top: 10px;">THE GAME</h2>

      <div class="g-container" fxLayout="column" fxLayoutAlign="center center">
        <ng-container *ngIf="st.secTillEnd == 0; else activeGame">
          <ng-container *ngIf="st.currentPrice == 0; else gameIsOver">
            <p>The game is currently not active.</p>
          </ng-container>
          <ng-template #gameIsOver>
            <div fxLayout="column" fxLayoutAlign="center center">
              <p>Game is over. Congratulations to the winners.</p>
              <p style="margin: 0;">Rewards and CATC will be transfered in next few days.</p>
              <p>Make sure you follow us on <a href="https://twitter.com/CatTownPunks" target="_blank">Twitter</a>.</p>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #activeGame>
          <ng-container *ngIf="(st.isAvailableToBid == true)">
            <p class="g-bid">Bids are open!</p>
          </ng-container>

          <div fxLayout="column" fxLayoutAlign="center center">
            <ng-container *ngIf="st.currentHolder != '0x0000000000000000000000000000000000000000'">
              <div fxLayout="row" fxLayoutGap="1em">
                <p class="g-name">Current holder:</p>
                <p class="g-data">
                  <b *ngIf="(st.currentHolder.toUpperCase() == currentUser.toUpperCase())">YOU</b>
                  <b
                    *ngIf="(st.currentHolder.toUpperCase() != currentUser.toUpperCase())">{{st.currentHolderFormated}}</b>
                </p>
              </div>
            </ng-container>

            <div fxLayout="row" fxLayoutGap="1em">
              <p class="g-name">Game ends in:</p>
              <p class="g-data">{{st.secTillEnd | dateAsSecondsToDaysCountdown}}</p>
            </div>

            <div fxLayout="row" fxLayoutGap="1em">
              <p class="g-name"> Bid price:</p>
              <p class="g-data">{{st.currentPriceFormated | number: '0.1-2'}}</p>
            </div>
          </div>

          <ng-container *ngIf="(st.isAvailableToBid == false)">
            <p class="g-bid-closed">Bids are closed. Will be available after {{st.waitTillNextBid | dateAsSecondsToDays}}.
            </p>
          </ng-container>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2rem" fxLayoutGap.xs="1rem" class="g-actions"
            fxLayoutAlign="center center">
            <button *ngIf="(hasAllowance==false)" mat-raised-button color="primary" class="btn-primary"
              (click)="approve()">APPROVE CONTRACT</button>
            <button
              *ngIf="(hasAllowance==true && st.isAvailableToBid == true && st.currentHolder.toUpperCase() != currentUser.toUpperCase())"
              mat-raised-button color="primary" class="btn-primary" (click)="bid()">MAKE A BID</button>
            <button mat-raised-button color="primary" class="btn-primary" (click)="refresh()">REFRESH</button>
          </div>

        </ng-template>
      </div>

      <p textColor="accent" class="rules-title"><b>Rules are simple:</b></p>

      <ul>
        <li>Starting price is <b>10 CATC</b>.</li>
        <li>Once bought you have <b>two hour cooldown</b> window before someone else can buy it from you.</li>
        <li>The new price is multiplied <b>by 1.25x</b></li>
        <li>The preveious owner gets back the CATC he spent plus the difference that the new owner paid. Minus the 5%
          commission.</li>
        <li>It is possible for <b>one winner</b> to get <b>both rewards</b> (holding the longest / bought last).</li>
        <li>All game participants except of the last one gets funds back at the <b>end of the round</b>.</li>
        <li>The round will last about <b>1 week</b></li>
        <li>The game may be extended after the last bid for 2 hour and 10 minutes</li>
      </ul>

      <p textColor="accent" class="rules-change"><b>{{ "Rules are subject to change" | uppercase}}</b></p>

      <h2 style="margin-bottom: 10px; margin-top: 0px;">REWARDS</h2>

      <div style="margin-bottom: 3rem;">
        <ng-container *ngIf="st.rewardIdLongest == 0 || st.rewardIdMaxPrice == 0; else rewards">
          Reward NFTs are not yet prepared
        </ng-container>
        <ng-template #rewards>
          <div fxLayout="row wrap">
            <div fxLayout="column" fxLayoutAlign="center center">
              <div class="pp-card" fxLayout="column">
                <a href="https://opensea.io/assets/klaytn/0xa9f07b1260bb9eebcbaba66700b00fe08b61e1e6/45810" target="_blank">
                  <div fxLayout="column" style="padding: 20px 20px 10px 20px">
                    <p class="pp-title-o">SheepFarm</p>
                    <p class="pp-title-o"><span class="pp-number">#199</span></p>
                  </div>
                  <img src="./assets/collector-dragon/sheepFarm199.png" alt="cat-img" class="pp-img">
                  <p class="pp-name" style="margin-bottom: 10px;">Miss Sommelier #199</p>
                </a>
              </div>
              <p style="margin-top: 0px;"> For longest holder</p>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center">
              <div class="pp-card" fxLayout="column">
                <a href="https://opensea.io/assets/klaytn/0xa9f07b1260bb9eebcbaba66700b00fe08b61e1e6/45921" target="_blank">
                  <div fxLayout="column" style="padding: 20px 20px 10px 20px">
                    <p class="pp-title-o">SheepFarm</p>
                    <p class="pp-title-o"> <span class="pp-number">#2013</span></p>
                  </div>
                  <img src="./assets/collector-dragon/sheepFarm2013.png" alt="cat-img" class="pp-img">
                  <p class="pp-name" style="margin-bottom: 10px;">pasture#2013</p>
                </a>
              </div>
              <p style="margin-top: 0px;">For highest bid</p>
            </div>
          </div>
        </ng-template>
      </div>
      <ng-container *ngIf="st.players.length > 0">
        <h2 style="margin-bottom: 20px; margin-top: 0px;">PLAYERS</h2>



        <mat-table [dataSource]="st.players" class="mat-elevation-z8" style="width: 100%;">
          <ng-container matColumnDef="address">
            <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
            <mat-cell *matCellDef="let player">
              <span *ngIf="(player.address.toUpperCase() != currentUser.toUpperCase())">{{player.addressFormated}}</span>
              <span *ngIf="(player.address.toUpperCase() == currentUser.toUpperCase())"><b>YOU</b></span>
              <mat-icon *ngIf="player.winner1" svgIcon="crown-3" style="margin-left: 1rem;"></mat-icon>
              <mat-icon *ngIf="player.winner2" svgIcon="crown-3" style="margin-left: 1rem;"></mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="timeHold">
            <mat-header-cell *matHeaderCellDef> Time holded </mat-header-cell>
            <mat-cell *matCellDef="let player"> {{player.timeHolded | dateAsSecondsToDays}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CATC">
            <mat-header-cell *matHeaderCellDef> CATC to return </mat-header-cell>
            <mat-cell *matCellDef="let player"> {{player.amountToReturn | number: '0.1-2'}} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <p style="margin-bottom: 3rem;">CATC will be <b>returned</b> when the game is over.</p>
      </ng-container>

    </ng-container>
    <ng-template #noGame>
      <h2 style="margin-bottom: 10px; margin-top: 0px;">THE GAME</h2>

      <p>
        Login with kaikas to view game details.
      </p>
    </ng-template>
    <h3 style="margin-bottom: 10px; margin-top: 0px;">Edition 설명 </h3>

    <p>
      메타 랜드의 Sheepfarm에서 목초지(Land)를 구입하고 자신의 농장을 운영하고 양을 키우고 돈을 벌 수 있습니다!  양(NFT)을 모아서 다른 플레이어와 경쟁하여 수익(PvP)을 올리는 P2E게임.
    </p>

    <h3 style="margin-bottom: 10px; margin-top: 0px;">Collector 소개</h3>

    <p>
      캣타운펑크스(이하 CTPS)의 OG 멤버이자 홀더께서 Sheep Farm NFT를 비딩게임에 올려 주셨습니다.
    </p>

    <p>
      CTPS의 OG 멤버분은 CTPS 프로젝트와 커뮤니티의 발전을 위해서 보이지 않는 곳에서도 항상 애쓰시고, 커뮤니티에 많은 기부와 후원을 아낌없이 베푸시며 애정을 보여 주십니다.
    </p>

    <p>
      개인의 콜렉션인  Sheep Farm NFT를 비딩게임에 추가 시킴으로서 CTPS의 서비스를 널리 알리고, 많은 프로젝트 분들이 함께 즐기시며, 결과적으로 클레이튼 생태계의 활성과 발전을 도모 하는 데 도움을 주시는 CTPS Collector께 감사를 표합니다.
    </p>

    <p>
      항상 저희에게 큰 기쁨을 주시는 CTPS Collector님의 행복과 건강을 바라며!
    </p>

    <p>
      모두 광클 준비 하시기 바랍니다!!!!!
    </p>
    <p>
      Let's Go!!!!!
    </p>





    <br>
    <p><a href="https://linktr.ee/cattownpunks" target="_blank" style="text-decoration: underline;">Check out our linktree</a></p>




    <br>
    <small textColor="font-dark">
      DISCLAIMER: The game rules may not be final and may change before game launch.
    </small>

  </div>
</div>
