<div class="p-container"  style="max-width: 1176px;">
  <h1>MeYou Minting</h1>
  <h2>Intro</h2>
  <p>
    MeYou is the second stage of Cat Town Punks' roadmap that will lead to Cat Universe. It will be utilized/used in the new dApp called Cat Tiffany, and you will receive an airdrop of a Pet Avatar NFT per a MeYou. In 'Cat Tiffany', you can select the role you want in each of the 4 stages to create a specific product, You can earn Catcoin $CATC by selling processed products to the Shop. Jewels, the last stage of processing, can be used to purchase nesseary items in Cat Universe, or You will be able to sell it to those who need it in secondary market. MeYou and Cat Tiffany are basic ecosystems of virtual space that simplify the economic structure of the real world.
  </p>

  <h2>Minting</h2>

  <p *ngIf="isConnected && secTillStart>0">
    OG mining opens in: <br>
    <app-market-countdown *ngIf="secTillStart>0"
      title="mintPriceAuction.title"
      [secondsTillCountdown]="secTillStart">
    </app-market-countdown>
  </p>


  <p *ngIf="!isConnected">
    Connect with kaikas first!
  </p>


    <app-height-spacer height="5rem"></app-height-spacer>

    <div fxLayout="column" *ngIf="isConnected">
      <div fxLayout="row" class="account-card" fxLayoutGap="2rem">
        <img src="assets/meyou/meyou.png" style="height: 100%;">
        <div fxlayout="column">
          <p class="account-title">YOUR ACCOUNT</p>
          <p class="account-number">{{walletAddressShort}}</p>
          <p class="a-title">NFT CONTARCT ADDRESS</p>
          <p class="a-number">{{contractAddress}}</p>
          <p class="a-title">OG AVAILABLE FOR PURCHASE WITH KLAY</p>
          <p class="a-number">{{klayLeft}}/1500</p>
          <p class="a-title">OG AVAILABLE FOR PURCHASE WITH CATC</p>
          <p class="a-number">{{catcLeft}}/1500</p>
          <p class="a-title">AVAILABLE FOR WHITELIST</p>
          <p class="a-number">{{whiteListLeft}}/3000</p>
          <p class="a-title">AVAILABLE FOR PUBLIC SALE</p>
          <p class="a-number">{{publicLeft}}/2500</p>
        </div>
      </div>

      <app-height-spacer height="5rem"></app-height-spacer>

      <div fxLayout="column" fxLayoutGap="3rem" style="width: 70%;" *ngIf="isConnected && secTillStart==0 && mintData.og1">
        <div>
        <h2>OG BUY WITH KLAY</h2>
        <h3 class="login-price">{{klayPrice}} KLAY / MeYou</h3>
        </div>
        <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
          <p class="b-text">We begin with only one...</p>
          <button mat-raised-button color="primary" class="b-button" (click)="buyNft(1)">Buy 1 with KLAY</button>
        </div>

        <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
          <p class="b-text">A good starter pack</p>
          <button mat-raised-button color="primary" class="b-button" (click)="buyNft(5)">Buy 5 with KLAY</button>
        </div>

        <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
          <p class="b-text">Now we are taking!</p>
          <button mat-raised-button color="primary" class="b-button" (click)="buyNft(10)">Buy 10 with KLAY</button>
        </div>

        <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
          <p class="b-text">Are you trying to get a LEGENDARY!? You are the hunter!</p>
          <button mat-raised-button color="primary" class="b-button" (click)="buyNft(50)">Buy 50 with KLAY</button>
        </div>
        <div fxLayout="column" style="width: 100%;" fxLayoutAlign="center center">
          <p class="b-afford" *ngIf="true">You can afford max {{canBuyKlay}} Meyou with KLAY.</p>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="3rem" style="width: 70%;" *ngIf="isConnected && secTillStart==0  && mintData.og1">
        <div >
          <h2 style="margin-bottom:0px">OG BUY WITH CATC</h2>
          <h3 class="login-price">{{catcPrice}} CATC / MeYou</h3>
        </div>
        <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex *ngIf="!hasAllowance">
          <p class="b-text">You need to allow our smart contract to spend CATC.</p>
          <button mat-raised-button color="primary" class="b-button" (click)="approve()">Approve</button>
        </div>
        <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex *ngIf="hasAllowance">
          <p class="b-text">One cat will be lonely...</p>
          <button mat-raised-button color="primary" class="b-button" (click)="buyNftCatc(1)">Buy 1 with CATC</button>
        </div>

        <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex *ngIf="hasAllowance">
          <p class="b-text">A proper amount for every MeYou holder.</p>
          <button mat-raised-button color="primary" class="b-button" (click)="buyNftCatc(5)">Buy 5 with CATC</button>
        </div>

        <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex *ngIf="hasAllowance">
          <p class="b-text">A CATC millionare?</p>
          <button mat-raised-button color="primary" class="b-button" (click)="buyNftCatc(10)">Buy 10 with CATC</button>
        </div>

        <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex *ngIf="hasAllowance">
          <p class="b-text">Is this even possible?!?</p>
          <button mat-raised-button color="primary" class="b-button" (click)="buyNftCatc(50)">Buy 50 with CATC</button>
        </div>
        <div fxLayout="column" style="width: 100%;" fxLayoutAlign="center center">
          <p class="b-afford" *ngIf="true">You can afford max {{canBuyCatc}} MeYou with CATC.</p>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="3rem" style="width: 70%;" *ngIf="isConnected && secTillStart==0  && mintData.whiteList">
        <div >
          <h2 style="margin-bottom:0px">WHITELIST SALE</h2>
          <h3 class="login-price">{{whiteListPrice}} KLAY / MeYou</h3>
        </div>

        <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
          <p class="b-text">We begin with only one...</p>
          <button mat-raised-button color="primary" class="b-button" (click)="buyNftWhiteList(1)">Buy 1 with KLAY</button>
        </div>

        <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
          <p class="b-text">A good starter pack</p>
          <button mat-raised-button color="primary" class="b-button" (click)="buyNftWhiteList(5)">Buy 5 with KLAY</button>
        </div>

        <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
          <p class="b-text">Now we are taking!</p>
          <button mat-raised-button color="primary" class="b-button" (click)="buyNftWhiteList(10)">Buy 10 with KLAY</button>
        </div>

        <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
          <p class="b-text">Are you trying to get a LEGENDARY!? You are the hunter!</p>
          <button mat-raised-button color="primary" class="b-button" (click)="buyNftWhiteList(50)">Buy 50 with KLAY</button>
        </div>
        <div fxLayout="column" style="width: 100%;" fxLayoutAlign="center center">
          <p class="b-afford" *ngIf="true">You can afford max {{canBuyKlayWhiteList}} Meyou with KLAY.</p>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="3rem" style="width: 70%;" *ngIf="isConnected && secTillStart==0 ">
        <div >
          <h2 style="margin-bottom:0px">PUBLIC SALE</h2>
          <h3 class="login-price">{{publicPrice}} KLAY / MeYou</h3>
        </div>

        <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
          <p class="b-text">We begin with only one...</p>
          <button mat-raised-button color="primary" class="b-button" (click)="buyNftPublic(1)">Buy 1 with KLAY</button>
        </div>

        <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
          <p class="b-text">A good starter pack</p>
          <button mat-raised-button color="primary" class="b-button" (click)="buyNftPublic(5)">Buy 5 with KLAY</button>
        </div>

        <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
          <p class="b-text">Now we are taking!</p>
          <button mat-raised-button color="primary" class="b-button" (click)="buyNftPublic(10)">Buy 10 with KLAY</button>
        </div>

        <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
          <p class="b-text">Are you trying to get a LEGENDARY!? You are the hunter!</p>
          <button mat-raised-button color="primary" class="b-button" (click)="buyNftPublic(50)">Buy 50 with KLAY</button>
        </div>
        <div fxLayout="column" style="width: 100%;" fxLayoutAlign="center center">
          <p class="b-afford" *ngIf="true">You can afford max {{canBuyKlayPublic}} Meyou with KLAY.</p>
        </div>
      </div>

      <app-height-spacer height="5rem"></app-height-spacer>

    </div>

    <app-height-spacer height="10rem"></app-height-spacer>



  <h2>IMPORTANT INFORMATION</h2>
  <p>
    <img src="/assets/meyou/meyouwin.png">
  </p>
  <p>
    <img src="/assets/meyou/meyou1.png">
    <br><br>
    <img src="/assets/meyou/meyou2.png">
    <br><br>
    <img src="/assets/meyou/meyou3.png">
    <br><br>
  </p>

  <h2>OG-0</h2>
  <p>
    Check if you qualify to buy MeYou cats with catcoin/klay and free mint?
  </p>
  <p>
    <b>{{mintData.og0?"Congratulation, You are OG-0 member.":"Sorry, you don't qualify for OG-0."}}</b>
  </p>

  <h2>OG-1</h2>
  <p>
    Check if you qualify to buy MeYou cats with catcoin/klay?
  </p>
  <p>
    <b>{{mintData.og1?"Congratulation, You are OG-1 member.":"Sorry, you don't qualify for OG-1."}}</b>
  </p>

  <h2>Free Mint</h2>
  <p>To our greatest supporters we will give few MeYou for free. Check If You qualify.</p>
  <p><b>Number of free mint: {{mintData.freeMint}}</b></p>
</div>

