import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'road-map-countdown',
  templateUrl: './road-map-countdown.component.html',
  styleUrls: ['./road-map-countdown.component.scss']
})
export class RoadMapCountdownComponent implements OnInit {

  @Input() timerInSeconds: any;
  @Input() countdownDate: any;
  timer: string = "";

  demo: any
  days: any;
  hours: any;
  minutes: any;
  seconds: any;
  showCountDown: boolean = true;

  constructor() { }

  x = setInterval(() => {
    if(this.countdownDate) {
      var now = new Date();
      var distance = this.countdownDate.valueOf() - now.valueOf();
      this.days = Math.floor(distance/(1000*60*60*24));
      this.hours = Math.floor((distance % (1000*60*60*24) / (1000*60*60)));
      this.minutes = Math.floor((distance % (1000*60*60) / (1000*60)));
      this.seconds = Math.floor((distance % (1000*60) / 1000));
      if(this.seconds < 0 ) {
        this.showCountDown = false;
      } else {
        this.showCountDown = true;
      }
    }
  }, 1000)

  ngOnInit() {
  }

}
