import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CouponService } from 'src/app/core/services/coupon.service';
import { ResponseSnackbarComponent } from 'src/app/shared/components/response-snackbar/response-snackbar.component';

@Component({
  selector: 'app-coupon-test',
  templateUrl: './coupon-test.component.html',
  styleUrls: ['./coupon-test.component.scss']
})
export class CouponTestComponent implements OnInit {

  constructor(private couponService:CouponService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  mint() {
    this.couponService.mint5().subscribe(()=>{
      this.snackBar.openFromComponent(ResponseSnackbarComponent, {
        horizontalPosition: "center",
        verticalPosition: "bottom",
        duration: 6000,
        data: "CREATED!"
      });
    });
  }

}
