<div *ngIf="showCat && catItem as async" class="pp-card" fxLayout="column" [ngStyle]="{'border': (isChecked) ? 'solid 4px #B00' : 'none','opacity': (isDisabled)?'30%':'100%'}" style="position: relative; min-height: 470px">
    <mat-icon *ngIf="isChecked" style="color: #B00; position:absolute; top: 0px; right:0.5em; font-size:3em">check_circle</mat-icon>
    <a [routerLink]="(checkMode==false || showMenu==false)?'/cat-profile/'+ itemId:null" (click)="onClick($event); false">
      <div fxLayout="column" style="padding: 20px 20px 10px 20px">
        <p class="pp-title-o">CAT TOWN</p>
        <p class="pp-title-o">PUNKS <span class="pp-number">#{{catItem.number}}</span></p>
      </div>
      <img [src]="catItem.image" alt="cat-img" class="pp-img">
      <p class="pp-name">{{catItem.name}}</p>
      <p class="pp-rare">{{catItem.attributes[0].value}}</p>
    </a>
    <p *ngIf="(showMenu==true)" class="pp-footer">
      <span *ngIf="(amount>0)"><img src="assets/catcoin-50X50.png" class="p-img-small" alt="cat-coin">{{amountMinted | number : '1.2-2'}}</span>
      <button *ngIf="(checkMode==false)" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions...">
        <mat-icon>more_horiz</mat-icon>
      </button>
    </p>
</div>
<mat-menu #menu="matMenu">
  <button mat-menu-item *ngIf="(amount>0)" (click)="startCollecting()">
    <mat-icon>savings</mat-icon>
    <span>Collect CatCoin</span>
  </button>
  <button mat-menu-item  (click)="startTransfer()">
    <mat-icon>move_up</mat-icon>
    <span>Transfer Cat</span>
  </button>
</mat-menu>
