import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  private totalRequests = 0;

  constructor(public loaderService: LoaderService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.totalRequests++;
    let finished = false;

    setTimeout(() => {
      if (!finished) {
        this.loaderService.isLoading.next(true);
      }
    }, 500);

    return next.handle(req).pipe(
      finalize(
        () => {
          this.totalRequests--;
          if (this.totalRequests === 0) {
            finished = true;
            this.loaderService.isLoading.next(false);
          }
        }
      )
    )
  }

}
