import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { delay, map, shareReplay, take, tap } from 'rxjs/operators';
import { Observable, pipe, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { KaikasService, LoginInfo } from './core/services/kaikas.service';
import { LoaderService } from './core/services/loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ModalCollectAllCatCoinsComponent } from './layout/modal-collect-all-cat-coins/modal-collect-all-cat-coins.component';

providers: [
  { provide: Window, useValue: window }
]

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'cat-punks';
  currentRouteName: string = "";
  panelOpenState = false;
  // IS LAYOUT MOBILE ?
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  isTablet$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Tablet)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  isWeb$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Web)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.XSmall
  );

  public loginInfo: Observable<LoginInfo>;
  public isConnected:boolean = false;
  public address:string = "";
  public shortAddress:string  = "";
  public isLoading: boolean = true;
  public catCoins:number = 0;
  public klay:number = 0;
  private onLoginSubscription?: Subscription;

  constructor(
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    public kaikas: KaikasService,
    private loader: LoaderService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private meta: Meta
  ) {
    this.matIconRegistry.addSvgIcon(`kakao`, this.domSanitizer.bypassSecurityTrustResourceUrl("assets/kakao-talk.svg"));
    this.matIconRegistry.addSvgIcon(`open-sea`, this.domSanitizer.bypassSecurityTrustResourceUrl("assets/open-sea-white.svg"));
    this.matIconRegistry.addSvgIcon(`open-sea-full`, this.domSanitizer.bypassSecurityTrustResourceUrl("assets/OpenSea-Full-Logo (light).svg"));
    this.matIconRegistry.addSvgIcon(`open-sea-transparent`, this.domSanitizer.bypassSecurityTrustResourceUrl("assets/Logomark-Transparent White.svg"));
    this.matIconRegistry.addSvgIcon(`telegram`, this.domSanitizer.bypassSecurityTrustResourceUrl("assets/telegram.svg"));
    this.matIconRegistry.addSvgIcon(`twitter-white`, this.domSanitizer.bypassSecurityTrustResourceUrl("assets/twitter-white.svg"));
    this.matIconRegistry.addSvgIcon(`twitter-blue`, this.domSanitizer.bypassSecurityTrustResourceUrl("assets/twitter-blue.svg"));
    this.matIconRegistry.addSvgIcon(`crown-3`, this.domSanitizer.bypassSecurityTrustResourceUrl("assets/crown-icon-3.svg"));

    this.meta.addTags([
      { name: 'description', content: 'Cat Town Punks is a decentralized social network for cats. It is a place where cats can share their stories, photos and more.' },
      { name: 'catCoins', content: 'Cat coin is a digital asset that is used to pay for services and content on Cat Town Punks. It is issued by the Cat Town Punks team and can be used to purchase Cat Town Punks content such as upgrading their cats.' },
    ])

    // OPEN GRAPH TAGS //
    this.meta.addTag({ name: 'og:site_name', content: 'Cat Town Punks' });
    this.meta.addTag({ name: 'og:type', content: 'website' });
    this.meta.addTag({ name: 'og:url', content: 'https://cattownpunks.com' });
    this.meta.addTag({ name: 'og:title', content: 'Cat Town Punks' });
    this.meta.addTag({ name: 'og:description', content: 'Cat Town Punks is a decentralized social network for cats. It is a place where cats can share their stories, photos and more.' });
    this.meta.addTag({ name: 'og:image', content: 'https://cattownpunks.com/assets/3.png' });

    // TWITTER TAGS //
    this.meta.addTag({ name: 'twitter:card', content: 'summary' });
    this.meta.addTag({ name: 'twitter:site', content: '@CatTownPunks' });
    this.meta.addTag({ name: 'twitter:creator', content: '@CatTownPunks' });
    this.meta.addTag({ name: 'twitter:title', content: 'Cat Town Punks' });
    this.meta.addTag({ name: 'twitter:description', content: 'Cat Town Punks is a decentralized social network for cats. It is a place where cats can share their stories, photos and more.' });
    this.meta.addTag({ name: 'twitter:image', content: 'https://cattownpunks.com/assets/3.png' });

  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        switch (event.url) {
          case "/mint":
            this.currentRouteName="Mint, get random cat";
            break;
          case "/cat-profile/105":
            this.currentRouteName="Cat Profile";
            break;
          case "/test-mint":
              this.currentRouteName="Test Mint";
              break;
          case "/cat-coin":
            this.currentRouteName="CatCoin";
            break;
          case "/cat-stories":
            this.currentRouteName="Cat Stories";
            break;
          default:
            this.currentRouteName="Cat Town Punks";
            break;
        }
      }
    });

    this.loader.isLoading.pipe(delay(0)).subscribe((loading) =>{
      this.isLoading = loading
    });

    this.loginInfo = this.kaikas.loginInfoObserver;

    this.onLoginSubscription = this.kaikas.loginInfoObserver.subscribe((loginInfo:LoginInfo)=>{
      this.isConnected = loginInfo.isConnected;
      if (loginInfo.isConnected == true) {
        this.address = loginInfo.address;
        this.shortAddress = this.kaikas.shortAddress(this.address);
        this.catCoins = loginInfo.catCoins;
        this.klay = loginInfo.klay;
        if (loginInfo.lastError != "")
          alert(loginInfo.lastError);
      }
    }, (error)=> {
      alert(error);
    });
    this.kaikas.silentLogin();
  }

  doRefresh() {
    this.kaikas.login();
  }

  selectCurrentRoute(name: string) {
    this.currentRouteName = name;
  }

  ngOnDestroy() {
    this.onLoginSubscription?.unsubscribe();
  }

  openDialogCollectCatCoins(collectionType: string): void {
    if (collectionType == "top50") {
      this.kaikas.getTop50CatsMined().pipe(take(1)).subscribe((collectableData: any)=>{
        this.openDialogforCatCoinCollect({amount:collectableData.amount, type: collectionType, catIds: collectableData.catIds});
      });
    } else {
      this.kaikas.getAllCatsMined().pipe(take(1)).subscribe((amount:number) => {
        this.openDialogforCatCoinCollect({amount:amount, type: collectionType});
      });
    }
  }

  private openDialogforCatCoinCollect(data:any) {
    const dialogRef = this.dialog.open(ModalCollectAllCatCoinsComponent, {
      width: '600px',
      maxWidth: '100vw',
      autoFocus: false,
      data: data
    });

    const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (size.matches) {
        dialogRef.updateSize('100vw', '100vh');
      } else {
        dialogRef.updateSize('600px', '');
      }
    });
  }


}
