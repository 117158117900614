import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BreakpointState, Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { DarkRitualService } from 'src/app/core/services/darkritual.service';
import { IKaikasTransaction, KaikasService } from 'src/app/core/services/kaikas.service';
import { CatShopApprovalComponent } from 'src/app/features/cat-profile/cat-shop-approval/cat-shop-approval.component';
import { ResponseSnackbarComponent } from 'src/app/shared/components/response-snackbar/response-snackbar.component';
import { FormControl } from '@angular/forms';
import { CouponService } from 'src/app/core/services/coupon.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-confirm-dark-ritual',
  template: `
    <div fxLayout="column">
      <h1  class="modal-title">{{title}}</h1>
      <div mat-dialog-content class="modal-text" style="text-align: center;">
        <p>Are you sure you want to perform Dark Ritual for <span textColor="accent" style="font-weight: bold;">{{(useCouponFC.value==false)?((data.price | number: '0.1-6') + ' CatCoins'):('1 coupon')}}</span>?</p>
        <mat-checkbox *ngIf="data.coupons>0" [formControl]="useCouponFC">Use coupon (you have {{data.coupons}} coupons)</mat-checkbox>

      </div>
      <div mat-dialog-actions align="end">
        <button mat-button (click)="onNoClick()" class="modal-button">No</button>
        <button mat-raised-button color="primary" class="modal-button" (click)="onYesClick()">Yes</button>
      </div>

      <div *ngIf="isLoading" mat-dialog-actions align="end">
        <p>Please confirm on Kaikas Vallet</p>
      </div>
    </div>
  `,
  styles: [`
    .modal-title {
      margin-top: 0px;
      text-align: center;
    }
    .check-box {
      width: 1.6em;
      height: 1.6em;
    }
    .mat-dialog-content {
      overflow: visible;
    }
    `]
})
export class ModalConfirmDarkRitualComponent implements OnInit {

  public isLoading: boolean = false;
  public userDeniedTx: boolean = false;
  public title: string = "Warning";
  public titleIcon: string = "thumb_up_off_alt";
  public randowTweetHref: string = "";
  public hasAllowanceCatcoin:boolean = false;
  public hasAllowanceCoupon:boolean = false;
  public useCouponFC = new FormControl(false);

  constructor(
    private _snackBar: MatSnackBar,
    private kaikas: KaikasService,
    public dialogRef: MatDialogRef<ModalConfirmDarkRitualComponent>,
    private darkRitualService: DarkRitualService,
    private couponService: CouponService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.XSmall
  );

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    if (this.useCouponFC.value == true) {
      if(this.hasAllowanceCoupon) {
        this.darkRitualService.makeRitualCoupon(this.data.catId);
        this.dialogRef.close();
      } else {
        this.openApprovalCouponDialog();
      }
    } else {
      if(this.hasAllowanceCatcoin) {
        this.darkRitualService.makeRitual(this.data.catId, this.data.price);
        this.dialogRef.close();
      } else {
        this.openApprovalDialog(this.data.price);
      }
    }

  }

  userConfirmedTransaction() {
    this.isLoading = false;
    this.dialogRef.close();
  }

  ngOnInit() {
    this.refreshAllowance();
  }

  public refreshAllowance() {
    this.darkRitualService.hasAllowanceToDoRitual().pipe(take(1)).subscribe((hasAllowance)=>{
      this.hasAllowanceCatcoin = hasAllowance;
    });
    this.couponService.hasAllowance(environment.contractDarkRitual).pipe(take(1)).subscribe((hasAllowance)=>{
      this.hasAllowanceCoupon = hasAllowance;
    });
  }
  openApprovalCouponDialog(): void {
    const dialogRef = this.dialog.open(CatShopApprovalComponent, {
      width: '500px',
      maxWidth: '100vw',
      data: {type: "coupon"},
      autoFocus: false
    });

    const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (size.matches) {
        dialogRef.updateSize('100vw', '100vh');
        dialogRef.addPanelClass('my-custom-dialog-class');
      } else {
        dialogRef.updateSize('500px', '');
        dialogRef.removePanelClass('my-custom-dialog-class');
      }
    });



    dialogRef.afterClosed().subscribe(result => {
      if(result == 'approve') {
        this.couponService.makeAllowance(environment.contractDarkRitual).pipe(take(1)).subscribe({
          complete: ()=>{
            this.refreshAllowance();
          }
        });
      } else {
        console.log("It wasn't approved!")
      }
    });
  }

  openApprovalDialog(value: number): void {
    const dialogRef = this.dialog.open(CatShopApprovalComponent, {
      width: '500px',
      maxWidth: '100vw',
      data: {type: "catcoin"},
      autoFocus: false
    });

    const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (size.matches) {
        dialogRef.updateSize('100vw', '100vh');
        dialogRef.addPanelClass('my-custom-dialog-class');
      } else {
        dialogRef.updateSize('500px', '');
        dialogRef.removePanelClass('my-custom-dialog-class');
      }
    });



    dialogRef.afterClosed().subscribe(result => {
      if(result == 'approve') {
        this.darkRitualService.makeAllowance().pipe(take(1)).subscribe({
          complete: ()=>{
            this.refreshAllowance();
          }
        });
      } else {

      }
    });
  }

}
