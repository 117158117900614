export const environment = {
  production: true,
  catsUrl: "Dev.cattownpunks.si",
  contractNft: "0xD8e5Bb7159622d6A08b131363EB9f0E46b6700Ed",
  contractCoin: "0xBf2Dd2f3f3f8FfFb1A39a4a2013Db1F43a1B650A",
  contractShop: "0x2b68266570C7434Ee7894363dAEfad034e34DA5c",
  contractGame: "0xE07abfdD5F014f0A44D065728af712D44Cc23142",
  contractOracle: "0x69fCBD792A839A88F10791F647934087d3C44c7E",
  contractPresale: "0x94Cf9A2eA7C84cBd28361F35D17fE9044D4da435",
  contractHotCat: "0xc63f89136052e3e4f08fa1d403bC507Aa83c9516",
  contractDarkRitual: "0x1B8B84bb6165710AA4FA99aC2E0d409F75b483E6",
  contractCoupon: "0xA3c4eA3d192D8dFD32f4A096c03048bCD8D4A5cD",
  contractMeYouOracle: "0xAbDd6b121e85868352Fc7751e78415B39A751a3E",
  contractMeYouMiner: "0x1A93e80cc6864BAa4F384B2f2A79db7248e51C84",
  contractMeYouNft: "0xDFBB2359c9ABba70a6E65C2fcAFE53BE8DA89c95",
  contractMeYouUtils: "",
  chanId: "1001",
  invalidNetworkMessage: "Invalid network. Please switch to baobab test net and refresh this page.",
  tokenUrl: "https://test-token.cattownpunks.com",
  meYouTokenUrl: "http://localhost:3101",
  apiUrl: "https://test-api.cattownpunks.com",
  discordAuthUrl: "https://discord.com/api/oauth2/authorize?client_id=897546853883740170&redirect_uri=https%3A%2F%2Ftest.cattownpunks.com%2Fdiscord-verification&response_type=code&scope=identify",
  env: "testp",
  discord: {
    clientId: "897546853883740170",
    clientSecret: "o5GiLfsb0sM7Ytq_joITNNUvodjQkcAs",
    discordToken : "ODk3NTQ2ODUzODgzNzQwMTcw.YWXPpQ.CmhkpZkmtE784EV8Zbmal7l78Eo",
    accessKeyId : "KASK3P2DKTYVV1R0M4PNS568",
    secretAccessKey : "iUKT0k6ZQzv9IiUdVh5iETQzMorGXjQtQ"
  },
  //https://www.googleapis.com/drive/v3/files?q=%271fMhrzDgdSW60qCzDBfweuc_ijwT1SsYR%27%20in%20parents%20and%20trashed%20%3D%20false&key=AIzaSyApflRHCAZSXv33hCIlvpvFPmTDf3RxxFI
  pages: {
    events: "1NhiwaMSUDaGVXxkUG8CR47EPD8zKLMbonEp8-GbrETQ",  //Events (testnet)
    roadmap: "1sBsxkhhE8pvtqbnDwyc1tGP_4IPCQCKhXgI0FFUccjw",
    sandbox: "1pqul-w7R3ItUh3Azdd6wyfVOXiFTdL2iCQ_LGyI7A0c",
    biddingGames: {
      zodiac: "1AC8_e0L0t6PsnaFT71om0IC5JsyE9rSpumBEscneZys"
    }
  }

};
