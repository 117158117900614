<div *ngIf="even">
  <div fxLayout="column" fxLayoutAlign="center" *ngIf="catStory as item">
    <!-- MOBILE -->
    <div fxLayout="row" fxShow.xs fxHide.gt-xs>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5rem" fxLayoutGap.xs="1rem" fxLayoutAlign="center center">
        <div class="p2m-card">
          <div class="p2m-header">CAT TOWN PUNKS
            <span class="p2m-number">{{item.number}}</span>
          </div>
          <img [src]="item.imgLink" style="width: 100%;">
          <h2 class="p2m-name">{{item.name}}</h2>
          <h3 class="p2m-rare">{{item.rarity}}</h3>
        </div>
        <div class="p2m-content" fxLayout="column" fxLayoutAlign="center center">
          <p class="p2m-text" *ngIf="item.foreignDescription"><strong>{{item.foreignDescription}}</strong></p>
          <p class="p2m-text">{{item.description}}</p>
          <a fxLayoutAlign="center center" mat-raised-button color="primary" target="_blank" class="btn-primary p2m-btn" href="{{item.url}}">OpenSea Profile</a>
        </div>
      </div>
    </div>
    <mat-divider *ngIf="!last" class="p2m-divider" fxShow.xs fxHide.gt-xs></mat-divider>
    <!-- DESKTOP -->
    <div fxLayout="row" fxHide.xs fxShow.gt-xs style="margin-bottom: 5rem;">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5rem" fxLayoutGap.xs="1rem" fxLayoutAlign="center center">
        <div class="p2-card">
          <img class="p-img" src="./assets/story_stack2.png" alt="story-collection">
          <div class="p2-div-img" [ngStyle]="{'background-image': 'url(' + item.imgLink + ')' }"></div>
          <h2 class="p2-number">{{item.number}}</h2>
          <h2 class="p2-name">{{item.name}}</h2>
          <h3 class="p2-rare">{{item.rarity}}</h3>
        </div>
        <div class="p2-content" fxLayout="column">
          <p *ngIf="item.foreignDescription" class="p2-text" style="margin-bottom: 20px;"><strong>{{item.foreignDescription}}</strong></p>
          <p class="p2-text" style="margin-bottom: 20px;">{{item.description}}</p>
          <a fxLayoutAlign="center center" mat-raised-button color="primary" target="_blank" class="btn-primary p2-btn" href="{{item.url}}">OpenSea Profile</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!even">
  <div fxLayout="column" fxLayoutAlign="center" *ngIf="catStory as item">
    <!-- MOBILE -->
    <div fxLayout="row" fxShow.xs fxHide.gt-xs>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5rem" fxLayoutGap.xs="1rem" fxLayoutAlign="center center">
        <div class="p2m-card">
          <div class="p2m-header">CAT TOWN PUNKS
            <span class="p2m-number">{{item.number}}</span>
          </div>
          <img [src]="item.imgLink" style="width: 100%;">
          <h2 class="p2m-name">{{item.name}}</h2>
          <h3 class="p2m-rare">{{item.rarity}}</h3>
        </div>
        <div class="p2m-content" fxLayout="column" fxLayoutAlign="center center">
          <p *ngIf="item.foreignDescription" class="p2m-text"><strong>{{item.foreignDescription}}</strong></p>
          <p class="p2m-text">{{item.description}}</p>
          <a fxLayoutAlign="center center" mat-raised-button color="primary" target="_blank" class="btn-primary p2m-btn" href="{{item.url}}">OpenSea Profile</a>
        </div>
      </div>
    </div>
    <mat-divider *ngIf="!last" class="p2m-divider" fxShow.xs fxHide.gt-xs></mat-divider>

    <!-- DESKTOP -->
    <div class="p2-card" fxLayout="row" fxLayoutGap="5rem" fxLayoutAlign="center center" fxHide.xs fxShow.gt-xs style="margin-bottom: 5rem;">
      <div class="p2-content" fxLayout="column">
        <p *ngIf="item.foreignDescription" class="p2-text"><strong>{{item.foreignDescription}}</strong></p>
        <p class="p2-text">{{item.description}}</p>
        <app-height-spacer height="20px"></app-height-spacer>
        <a fxLayoutAlign="center center" mat-raised-button color="primary" target="_blank" class="btn-primary p2-btn" href="{{item.url}}">OpenSea Profile</a>
      </div>
      <div style="float: left; position: relative;">
        <img class="p-img" src="./assets/story_stack2.png" alt="story-collection">
        <div class="p2-div-img" [ngStyle]="{'background-image': 'url(' + item.imgLink + ')' }"></div>
        <h2 class="p2-number">{{item.number}}</h2>
        <h2 class="p2-name">{{item.name}}</h2>
        <h3 class="p2-rare">{{item.rarity}}</h3>
      </div>
    </div>
  </div>
</div>
