import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { TransferService } from 'src/app/core/services/transfer.service';

@Component({
  selector: 'app-modal-transfer-cats',
  templateUrl: './modal-transfer-cats.component.html',
  styleUrls: ['./modal-transfer-cats.component.scss']
})
export class ModalTransferCatsComponent implements OnInit {
  addressFormControl = new FormControl('', [Validators.required, this.ethAdddressValidator(5)]);
  showAllowanceButton: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ModalTransferCatsComponent>,
    public transferService: TransferService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  userDeniedTx: boolean;

  ethAdddressValidator(val: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let val = this.transferService.getValidAddress(control.value);
      if (val == "")
        return {invalidAddress: {value: control.value}};// : null;

      if (val != control.value)
        control.setValue(val);
      return null;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  approve() {
    this.transferService.makeAllowance().pipe(take(1)).subscribe(()=>{
      this.refreshAllowance();
    });
  }

  transfer() {
    if (this.addressFormControl.valid == false)
      return;
    this.transferService.transferCats(this.data, this.addressFormControl.value);
    this.dialogRef.close();
  }

  refreshAllowance() {
    this.transferService.hasAllowanceToTransfer().pipe(take(1)).subscribe((hasAllowance)=>{
      this.showAllowanceButton = !hasAllowance;
    });
  }

  ngOnInit(): void {
    this.refreshAllowance();
  }

}
