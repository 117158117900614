<div class="pp-card" fxLayout="column">
  <a href="https://opensea.io/assets/klaytn/{{contract}}/{{token}}" target="_blank">
    <div fxLayout="column" style="padding: 20px 20px 10px 20px">
      <p class="pp-title-o">UNKNOWN</p>
      <p class="pp-title-o"><span class="pp-number">#{{token}}</span></p>
    </div>
    <img src="https://api.cattownpunks.com/api/nft/image/{{contract}}/{{token}}" alt="cat-img" class="pp-img">
    <p class="pp-name" style="margin-bottom: 10px;">UNKNOWN #{{token}}</p>
  </a>
</div>
