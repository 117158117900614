import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {

  @Input() link: string = "";
  @Input() wholeLink: string = "";
  @Input() anchorFragment: string = "";
  @Input() queryParams: any;

  constructor() { }

  ngOnInit(): void {

  }

}
