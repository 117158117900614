<div fxLayout="column">
  <!-- Desktop -->
  <h1 mat-dialog-title fxLayout="row" fxLayoutAlign="center center" fxShow.gt-xs fxHide.xs>
    <div fxFlex fxLayoutAlign="center" class="d-title">Approval required</div>
    <mat-icon color="primary" class="d-icon" fxLayoutAlign="end end">info</mat-icon>
  </h1>

  <!-- Mobile -->
  <mat-toolbar color="primary" fxHide.gt-xs fxShow.xs>
    <button mat-icon-button (click)="onNoClick()"><mat-icon>arrow_back</mat-icon></button>
    <span style="margin-left: 5px;">CatTownPunks</span>
  </mat-toolbar>
  <div fxFlex fxLayoutAlign="center" class="d-title" fxHide.gt-xs fxShow.xs>Approval required</div>

  <div mat-dialog-content fxLayoutAlign="center center" fxLayout="column" class="d-content">
    <p class="d-text">We need your approval to spend {{(data.type=="catcoin")?'CatCoins':"Coupons"}}.</p>
  </div>
  <app-height-spacer height="0.4rem"></app-height-spacer>
  <div mat-dialog-actions fxLayout="row"  fxLayoutAlign="center center">
    <button mat-raised-button color="primary" class="d-button" mat-dialog-close="approve">Approve</button>
  </div>
  <app-height-spacer height="1rem"></app-height-spacer>
</div>
