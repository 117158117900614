import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CouponService } from 'src/app/core/services/coupon.service';
import { KaikasService, LoginInfo } from 'src/app/core/services/kaikas.service';

@Component({
  selector: 'app-my-other',
  templateUrl: './my-other.component.html',
  styleUrls: ['./my-other.component.scss']
})
export class MyOtherComponent implements OnInit {
  private lastFetchedAddress:string = "";
  private onLoginSubscription?:Subscription;
  private onCouponFetchedSubscription?:Subscription;
  public items:any[];
  constructor(
    public kaikas: KaikasService,
    public couponService: CouponService
  ) { }

  ngOnInit(): void {
    this.onCouponFetchedSubscription = this.couponService.otherItems.subscribe((allItems)=>{
      this.items = allItems;
    })

    this.onLoginSubscription = this.kaikas.loginInfoObserver.subscribe((loginInfo:LoginInfo)=>{
      if (loginInfo.address != this.lastFetchedAddress) {
        this.lastFetchedAddress = loginInfo.address;
        this.couponService.fetchItems();
      }
    });
  }


  ngOnDestroy() {
    this.onLoginSubscription?.unsubscribe();
    this.onCouponFetchedSubscription?.unsubscribe();
  }
}
