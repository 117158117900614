import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { KaikasService, LoginInfo } from 'src/app/core/services/kaikas.service';
import { MeYouService } from 'src/app/core/services/me.you.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-me-you-minting',
  templateUrl: './me-you-minting.component.html',
  styleUrls: ['./me-you-minting.component.scss']
})
export class MeYouMintingComponent implements OnInit {

  constructor(
    public meYouService:MeYouService,
    private kaikas: KaikasService
  ) { }
  private statusSubscription:Subscription;
  private miningStatusSubcription:Subscription;
  private onLoginSubscription?:Subscription;
  public mintData:any = {};

  public isConnected:boolean = false;
  public walletAddress:string = "";
  public walletAddressShort:string = "";
  public addressAllowance:string = "";
  public contractAddress:string = "";
  public klayLeft:string = "???";
  public catcLeft:string = "???";
  public whiteListLeft:string = "???"
  public publicLeft:string = "???";
  public klayPrice:string = "165";
  public catcPrice:string = "30000";
  public whiteListPrice:string = "180";
  public publicPrice:string = "210";
  public secTillStart:number = 10;
  public canBuyKlay = 0;
  public canBuyCatc = 0;
  public canBuyKlayWhiteList = 0;
  public canBuyKlayPublic = 0;
  public userHasKlay = 0;
  public userHasCatc = 0;
  public hasAllowance:boolean = false;



  ngOnInit(): void {
    this.contractAddress = environment.contractMeYouNft;
    this.statusSubscription = this.meYouService.status.subscribe((data=>{
      console.log("mintData", data);
      this.mintData = data;
    }))

    this.miningStatusSubcription = this.meYouService.miningStatus.subscribe((data)=>{
      //      let ret:any = {klayPrice: 0, catcPrice:0, secBeforeStart:10, klayMeYouLeft: 0, catcMeYouLeft: 0};
      this.klayLeft = data.klayMeYouLeft;
      this.catcLeft = data.catcMeYouLeft;
      this.whiteListLeft = data.whiteListLeft;
      this.publicLeft = data.publicLeft;
      this.klayPrice = data.klayPrice;
      this.catcPrice = data.catcPrice;
      this.whiteListPrice = data.whiteListPrice;
      this.publicPrice = data.publicPrice;
      this.secTillStart = data.secBeforeStart;
      this.refreshCanBuy();
    });

    this.onLoginSubscription = this.kaikas.loginInfoObserver.subscribe((loginInfo:LoginInfo)=>{
      this.isConnected = loginInfo.isConnected;
      if (this.hasAllowance == false || this.addressAllowance != this.walletAddress)
        this.refreshAllowance();
      if (this.isConnected) {
        console.log(loginInfo);
        this.userHasKlay = loginInfo.klay;
        this.userHasCatc = loginInfo.catCoins;
        this.walletAddress = loginInfo.address;
        this.walletAddressShort = this.kaikas.shortAddress(loginInfo.address);
        this.meYouService.fetchStatus();
        this.meYouService.fetchMiningStatus();
        this.refreshCanBuy();
      } else {
        this.walletAddress = "NOT CONNECTED";
        this.userHasKlay = 0;
        this.userHasCatc = 0;
        this.canBuyKlay = 0;
        this.canBuyKlayWhiteList = 0;
        this.canBuyCatc = 0;
      }
    });


  }

  refreshCanBuy() {
    this.canBuyKlay = Math.floor(this.userHasKlay / +this.klayPrice);
    this.canBuyCatc = Math.floor(this.userHasCatc / +this.catcPrice);
    this.canBuyKlayWhiteList = Math.floor(this.userHasKlay / +this.whiteListPrice);
    this.canBuyKlayPublic = Math.floor(this.userHasKlay / +this.publicPrice);
    if (this.canBuyKlay > +this.klayLeft)
      this.canBuyKlay = (+this.klayLeft);
    if (this.canBuyCatc > +this.catcLeft)
      this.canBuyCatc = +this.catcLeft;
    if (this.canBuyKlayWhiteList > +this.whiteListLeft)
      this.canBuyKlayWhiteList = +this.whiteListLeft;
    if (this.canBuyKlayPublic > +this.publicLeft)
      this.canBuyKlayPublic = +this.publicLeft;

  }

  async buyNft(amount:number) {
    await this.meYouService.buyWithKlay(this.walletAddress, amount, amount * +this.klayPrice);
    this.kaikas.silentLogin();
  }

  async buyNftWhiteList(amount:number) {
    await this.meYouService.buyWithKlayWhiteList(this.walletAddress, amount, amount * +this.whiteListPrice);
    this.kaikas.silentLogin();
  }

  async buyNftPublic(amount:number) {
    await this.meYouService.buyWithKlayPublic(this.walletAddress, amount, amount * +this.publicPrice);
    this.kaikas.silentLogin();
  }

  public refreshAllowance() {
    this.meYouService.hasAllowanceToBid().pipe(take(1)).subscribe((hasAllowance)=>{
      this.hasAllowance = hasAllowance;
      if (hasAllowance == true)
        this.addressAllowance = this.walletAddress;
    });
  }

  public approve() {
    this.meYouService.makeAllowance().pipe(take(1)).subscribe({
      complete: ()=>{
        this.refreshAllowance();
      }
    });
  }

  async buyNftCatc(amount:number) {
    await this.meYouService.buyWithCatc(this.walletAddress, amount);
    this.kaikas.silentLogin();
  }

  ngOnDestroy() {
    this.statusSubscription?.unsubscribe();
    this.onLoginSubscription?.unsubscribe();
    this.miningStatusSubcription?.unsubscribe();
  }

}
