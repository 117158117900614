
<div fxLayout="column" fxLayoutAlign="center center">
  <div class="login-card" *ngIf="!userLoggedIn">
    <div style="margin: 2rem;" fxLayout="column" fxLayoutAlign="center center">
      <img src="assets/kaikas-logo.png" alt="kaikas-logo" class="login-img">
      <button  mat-raised-button color="primary" class="login-button" (click)="refresh()">LOGIN WITH KAIKAS</button>
    </div>
    <app-height-spacer height="6rem" fxHide.xs></app-height-spacer>
  </div>


  <div *ngIf="mintPrice>0" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1em">
    <h1 class="login-price">Price per NFT: {{mintPrice}} KLAY</h1>
  </div>
  <h2 *ngIf="mintPrice==-1" class="login-price">Price per NFT: Login first!</h2>

  <app-height-spacer height="5rem"></app-height-spacer>

  <div fxLayout="column" *ngIf="userLoggedIn">
    <div fxLayout="row" class="account-card" fxLayoutGap="2rem">
      <img src="assets/3.png" alt="account-image">
      <div fxlayout="column">
        <p class="account-title">YOUR ACCOUNT</p>
        <p class="account-number">{{userAccount}}</p>
        <p class="a-title">CONTARCT ADDRESS</p>
        <p class="a-number">{{contractAddress}}</p>
        <p class="a-title">CURRENTLY MINTED</p>
        <p class="a-number">{{currentSupply}}/{{maxSupply}}</p>
      </div>
    </div>

    <app-height-spacer height="5rem"></app-height-spacer>
    <div fxLayout="column" fxLayoutGap="3rem" style="width: 100%;" >
      <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
        <p class="b-text">We begin with only one...</p>
        <button mat-raised-button color="primary" class="b-button" (click)="buyNft(1)">Buy 1</button>
      </div>

      <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
        <p class="b-text">A good starter pack</p>
        <button mat-raised-button color="primary" class="b-button" (click)="buyNft(5)">Buy 5</button>
      </div>

      <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
        <p class="b-text">Now we are taking!</p>
        <button mat-raised-button color="primary" class="b-button" (click)="buyNft(10)">Buy 10</button>
      </div>

      <div class="b-card" fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
        <p class="b-text">Are you trying to get a LEGENDARY!? You are the hunter!</p>
        <button mat-raised-button color="primary" class="b-button" (click)="buyNft(100)">Buy 100</button>
      </div>
    </div>
    <app-height-spacer height="5rem"></app-height-spacer>
    <div fxLayout="column" style="width: 100%;" fxLayoutAlign="center center">
      <p class="b-afford" *ngIf="userLoggedIn">You can afford max {{userMaxNfts}} Cat Town Punks.</p>
    </div>
  </div>

  <app-height-spacer height="10rem"></app-height-spacer>

</div>
