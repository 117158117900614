<div fxLayout="column" class="cats-height" fxLayoutAlign="center center">
  <h1>My MeYou</h1>

  <br>
  <form [formGroup]="filterForm">
    <mat-card style="max-width: 1170px">
      <mat-card-content >
        <mat-form-field class="filter-rarity">
          <mat-label>Rarity</mat-label>
          <mat-select placeholder="Rarity" multiple  formControlName="rarity">
            <mat-option *ngFor="let rarity of rarityList; let i =index" [value]="rarity">
            {{rarity}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </form>



  <div fxLayout.gt-xs="row wrap" fxLayout.xs="column" fxLayoutAlign="center center">
    <div *ngFor="let item of myItems">
      <app-me-you-card [item]="item" itemId={{item.catId}} [showMenu]="false"></app-me-you-card>
    </div>
  </div>

  <div *ngIf="transferingInProgress" fxLayout.gt-xs="row wrap" fxLayout.xs="column" fxLayoutAlign="center center" style="position: fixed; bottom:0px; width:100%; height: 5em; background-color: white; border-top: #555 solid 1px;">
    Selected&nbsp;<b>{{selectedCats}}</b>&nbsp;cats.
    <button mat-raised-button color="primary" style="margin: 0.2em" (click)="completeTransfer()">Transfer</button>
    <button mat-raised-button style="margin: 0.2em" (click)="cancelCheckboxes()">Cancel</button>
  </div>
</div>
